.fixed-top {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.content {
    padding-top: 5px; /* Adjust this value to match the height of your navbar */
}

.section {
    padding: 5px;
}


.footer {
    background-color: #343a40; /* Dark background color */
    color: white; /* Text color */
    padding: 5px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}
